import { LOGOUT, AUTHENTICATE } from '../actions/AuthAction';
import { CheckToken } from '../Context/AuthContext';
import { CheckCache } from '../ApiGate';

const initialState = {
  userId: CheckCache('UID', true, 'userId'),
  name: CheckCache('UID', true, 'name'),
  email: CheckCache('UID', true, 'email'),
  phone: CheckCache('UID', true, 'phone'),
  alamat: CheckCache('UID', true, 'alamat'),
  tgl_lahir: CheckCache('UID', true, 'tgl_lahir'),
  success: CheckCache('UID', true, 'status'),
  level: CheckCache('UID', true, 'level'),
  jabatan: CheckCache('UID', true, 'jabatan'),
  token: CheckCache('UID', true),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE:
      // console.log('=== AUTHENTICATE ===', action);
      return {
        token: action.data.token,
        userId: action.data.userId,
        name: action.data.name,
        username: action.data.username,
        email: action.data.email,
        phone: action.data.phone,
        tgl_lahir: action.data.tgl_lahir,
        level: action.data.level,
        success: action.data.status,
        jabatanId: action.data.jabatanId,
        jabatan: action.data.jabatan,
      };

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};

// function name(params) {

// }
