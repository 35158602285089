import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

// routes config
import RouteData from './routes';
import Spinner from './Assets/loading/loading.gif';
// import PrivateRoute from './utility/privateRoute';
import { useSelector, useDispatch } from 'react-redux';
import * as AuthAction from './Store/actions/AuthAction';

const loadingLazy = (
  <div style={{ width: '100%', height: '100vh', position: 'fixed' }}>
    <div className='picture '>
      <div className='lazy-load'>
        <img src={Spinner} alt='Loading' style={{ width: '30px' }} />
      </div>
    </div>
  </div>
);
let UID = false;

const TheContent = () => {
  const [isLoading, setisLoading] = React.useState(true);
  const [USERS, setUSERS] = React.useState(false);
  // let windowSize = useWindowSize();
  // console.log('RouteData', RouteDapautata);
  const dispatch = useDispatch();
  UID = useSelector((state) => state.auth.userId);

  React.useEffect(() => {
    setisLoading(true);
    async function load() {
      // setUSERS(true);

      if (UID) {
        // console.log('UID TheContent 1', UID);
        setUSERS(UID);
      } else {
        // console.log('UID TheContent 2', UID);
        await dispatch(AuthAction.CheckAuthenticate());
      }
      setisLoading(false);
    }
    load();
  }, [UID, dispatch]);

  return (
    <Suspense fallback={loadingLazy}>
      <Routes>
        {!isLoading && (
          <>
            {RouteData.map((route, idx) => {
              const Compo = (props) => <route.component {...props} />;
              // return (
              //   <Route
              //     key={idx}
              //     path={route.path}
              //     exact={route.exact}
              //     name={route.name}
              //     element={<Compo />}
              //   />
              // );

              if (route.private) {
                if (USERS)
                  return (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      element={<Compo />}
                    />
                  );

                return (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={<Navigate to='/login' />}
                  />
                );
              } else if (route.reprivate) {
                if (USERS)
                  return (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      element={<Navigate to='/profile' />}
                    />
                  );

                return (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={<Compo />}
                  />
                );
              }

              return (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<Compo />}
                />
              );
            })}
          </>
        )}
      </Routes>
    </Suspense>
  );
};

const PrivateRoute = (props) => {
  const [isUser, setIsUser] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  // console.log('props1', props);

  React.useEffect(() => {
    let curCok = true;
    // console.log('curCok', curCok);
    // console.log('UserCookies', UserCookies);
    // console.log('cookie.loginStatus', cookie.loginStatus);
    //console.log('props', props);

    if (curCok) {
      setIsUser(true);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [loading, props]);

  if (isUser) {
    return <Route {...props} element={props.element} />;
  } else {
    return <Route {...props} element={<Navigate to='/login' />} />;
  }
};

export default React.memo(TheContent);
