import { LoginApi, SignupApi, ForgotPasswordApi } from '../Context/AuthContext';

import { CheckToken } from '../Context/AuthContext';
import { saveDataToStorage } from '../ApiGate';
import { AuthModel } from '../Model/AuthModel';

export const SIGNUP = 'SIGNUP';
export const SIGNIN = 'SIGNIN';
export const AUTHENTICATE = 'AUTHENTICATE';
export const LOGOUT = 'LOGOUT';

// const auth = getAuth();

export const Authenticate = (data) => {
  return async (dispatch) => {
    // await dispatch(GetHistory());
    dispatch({ type: AUTHENTICATE, data: { ...data } });
  };
};

export const CheckAuthenticate = () => {
  return async (dispatch) => {
    let res = await CheckToken();
    // console.log('CheckAuthenticate res', res);
  };
};

export const checkJob = (jobId) => {
  return async (dispatch, getState) => {
    let jabatan = getState().data.JobList;
    // console.log('checkJob', jobId, jabatan);
    jabatan = jabatan.find((job) => job.id === parseInt(jobId));
    // console.log('hasil', jabatan);
    if (jabatan) return jabatan.name;
    return null;
  };
};

export const signinAuthenticate = (Name, Password) => {
  return async (dispatch, getState) => {
    let res = await LoginApi(Name, Password);
    //console.log('SigninApi', res);
    if (res.success) {
      res = res.data;

      let level = getState().data.LevelList.find(
        (level) => parseInt(level.id) === parseInt(res.levelId)
      );

      let data = new AuthModel(
        res.id,
        res.name,
        res.email,
        res.phone,
        res.address,
        res.date_of_birth,
        true, // res.status,
        res.token,
        level.name,
        res.position // job
      );
      //console.log('data auth', data);
      dispatch(Authenticate(data));
      saveDataToStorage(data, new Date(), 'UID');
      return { success: true, data };
    }
    if (res.errors) {
      alert(res.errors);
      return { success: false, error: res.errors };
    }
    alert(res.message);
    return { success: false, error: res.message };
  };
};

export const signUpAuthenticate = (params) => {
  return async (dispatch, getState) => {
    let res = await SignupApi(params);
    //console.log('SignupApi', res);
    if (res.success) {
      res = res.data;
      let level = getState().data.LevelList.find(
        (level) => parseInt(level.id) === parseInt(res.levelId)
      );

      let data = new AuthModel(
        res.id,
        res.name,
        res.email,
        res.phone,
        res.address,
        res.date_of_birth,
        true, // res.status,
        res.token,
        level.name,
        res.position // job
      );
      //console.log('data', data);
      alert('Registrasi Sukses!');
      dispatch(Authenticate(data));
      saveDataToStorage(data, new Date(), 'UID');
      return { success: true, data };
    }
    let err;
    if (res.errors) {
      alert(res.errors);
      err = res.errors;
    }
    if (res.message) {
      alert(res.message[0]);
      err = res.message[0];
    }
    return { success: false, error: err };
  };
};

export const forgotPassword = (data) => {
  return async (dispatch, getState) => {
    let res = await ForgotPasswordApi(data);
    // console.log('ForgotPasswordApi', res);
    if (res.success) {
      // dispatch(Authenticate(res.uid, res.accessToken, res.expire));
      return res;
    } else {
      if (res.errors) alert(res.errors);
      return { success: false, error: res.errors };
    }
  };
};

export const logoutAct = () => {
  return (dispatch) => {
    localStorage.removeItem('UID');
    localStorage.removeItem('HISTORY');
    localStorage.removeItem('ABSEN');
    dispatch({ type: LOGOUT });
    return true;
  };
};

// ===============================
// '{"checkIn":{"status":true,"date_time":"2022-03-29 09:41:37","store":"34","type":"in","dataShift":{"id":1,"shift":"Pagi","in_start":"08:00:00","in_work":"09:30:00","in_off":"11:00:00","out_start":"17:00:00","out_work":"17:30:00","out_off":"19:00:00"},"request":{"latitude":"-6.5544207","longtitude":"106.8532496","time":"2022-03-29 09:41:37","shift_id":"1","store_id":"34"}},"checkOut":{"status":false,"startIn":"17:00:00"}}';
