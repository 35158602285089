import ApiGate from '../ApiGate';
import { surveyJson, questionsJson } from '../dummy/DataTesDummy';

export async function cekNilaiApi() {
  let functionName = 'cekNilai';
  let url = `/user/cek-penilaian`;

  return await ApiGate(url, 'GET', functionName, true);
}

export async function updateNilaiApi() {
  let functionName = 'updateNilai';
  let url = `/user/penilaian`;
  return await ApiGate(url, 'PUT', functionName, true);
}

export async function SubmitAnswerApi(params) {
  let functionName = 'SubmitAnswerApi';
  let url = `/user/penilaian`;
  return await ApiGate(url, 'POST', functionName, true, params);
}

export async function deleteHasilApi() {
  let functionName = 'deleteHasilApi';
  let url = `/user/hasil`;
  return await ApiGate(url, 'DEL', functionName, true);
}

// ===============================================

// ===============================================
