import axios from 'axios';
// import https from 'https';

export default async function ApiGate(
  uri,
  method,
  access = '',
  token = true,
  params = {},
  contentApp = 'application/json'
) {
  let response;
  let url = process.env.REACT_APP_URI_TEST + '/api/v1' + uri;
  // const agent = new https.Agent({ rejectUnauthorized: false });

  let headers = {
    headers: {
      'Content-Type': contentApp,
    },
    // httpsAgent: agent,
  };

  if (token) {
    let checkToken = await CheckCache('UID', true);
    if (!checkToken) {
      alert('Sesi telah berakhir, silahkan login kembali');
      window.location.href = '/logout';
    }

    headers = {
      headers: {
        ...headers.headers,
        token: checkToken,
      },
    };
  }
  // console.log('headers', headers);
  if (method === 'GET') {
    response = await axios
      .get(url, headers)
      .then((res) => {
        return { ...res.data, cache: false };
      })
      .catch((err) => erroreHandle(err));
  }

  if (method === 'POST') {
    response = await axios
      .post(url, params, headers)
      .then((res) => {
        return res.data;
      })
      .catch((err) => erroreHandle(err));
  }

  if (method === 'PUT') {
    response = await axios
      .put(url, params, headers)
      .then((res) => res.data)
      .catch((err) => erroreHandle(err));
  }

  if (method === 'DEL') {
    response = await axios
      .delete(url, headers)
      .then((res) => res.data)
      .catch((err) => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  async function erroreHandle(err) {
    if (err.message) {
      return { status: false, errors: err.message };
    }
    if (err.response) return err.response.data;
    return err;
  }

  // console.log('response', response);

  return response;
}

export const saveDataToStorage = (dataPass, expire, funcName) => {
  let data = JSON.stringify({
    ...dataPass,
    expiryDate: expire.getTime(),
  });
  localStorage.setItem(funcName, data);
};

export function CheckCache(params = '', token = false, data = '') {
  let localData = localStorage.getItem(params);

  if (!localData) {
    return null;
  } else {
    localData = JSON.parse(localData);
  }

  const now = new Date().getDate();
  const expi = new Date(localData.expiryDate).getDate();
  try {
    if (now !== expi) {
      localStorage.removeItem(params);
      return null;
    }
  } catch (exception) {
    return null;
  }

  if (token) {
    if (data === '') return localData.token;
    else return localData[data];
  }
  if (localData.data) return localData.data;
  return localData;
}

const json = {
  address: 'madang',
  date_of_birth: '2022-06-01',
  education: 'SS',
  email: 'aji@mail.com',
  level_id: 1,
  nama: 'aji',
  password: 'aji123',
  phone: '08999999',
  position: 'IT',
};
