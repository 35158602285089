export class SoalModel {
  constructor(
    id,
    soalType,
    listSoal,
    pengaturan,
    timeleft,
    listJawaban = [],
    answered = false
  ) {
    this.id = id;
    this.typeSoal = soalType;
    this.listSoal = listSoal; //answerOptions;
    this.pengaturan = pengaturan;
    this.timeleft = timeleft;
    this.answered = answered;
    this.listJawaban = listJawaban;
  }
}
export class JawabanModel {
  constructor(id, answerText, value, isCorrect) {
    this.id = id;
    this.answerText = answerText;
    this.value = value;
    this.isCorrect = isCorrect;
  }
}

export class QuizQuestionModel {
  constructor(id, answerText, isCorrect, value) {
    this.id = id;
    this.answerText = answerText;
    this.isCorrect = isCorrect;
    this.value = value;
  }
}

export class QuizModel {
  constructor(id, questionTitle, questionText, answerOptions) {
    this.id = id;
    this.questionTitle = questionTitle;

    this.questionText = questionText;
    this.answerOptions = answerOptions;
    this.isRequired = true;
  }
}

export class SurveyModel {
  constructor(
    // id,
    title,
    showProgressBar,
    showTimerPanel,
    maxTimeToFinishPage,
    maxTimeToFinish,
    firstPageIsStarted,
    startSurveyText,
    pages,
    completedHtml,
    completedHtmlOnCondition,
    choicesOrder
  ) {
    // this.id = id;
    this.title = title;
    this.showProgressBar = showProgressBar;
    this.showTimerPanel = showTimerPanel;
    this.maxTimeToFinishPage = maxTimeToFinishPage;
    this.maxTimeToFinish = maxTimeToFinish;
    this.firstPageIsStarted = firstPageIsStarted;
    this.startSurveyText = startSurveyText;
    this.pages = pages;
    this.completedHtml = completedHtml;
    this.completedHtmlOnCondition = completedHtmlOnCondition;
    this.choicesOrder = choicesOrder;
  }
}

export class SurveyQuestionModel {
  constructor(
    id,
    type,
    name,
    title,
    titleLocation,
    html,
    choices,
    correctAnswer
    // colCount = 3,
    // hasOther = true,
    // isRequired = true
  ) {
    this.id = id;
    this.type = type;
    this.name = name;
    this.title = title;
    this.titleLocation = titleLocation;
    this.html = html;
    this.choices = choices;
    this.correctAnswer = correctAnswer;
    // this.colCount = colCount;
    // this.hasOther = hasOther;
    this.isRequired = true;
  }
}

// ============================
