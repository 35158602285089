import { IS_LOADING, GET_NILAI } from '../actions/DataAction';
import { CheckCache } from '../ApiGate';

const initialState = {
  QuizFormat: CheckCache('QUIZ'),
  SurveyFormat: CheckCache('SURVEY'),
  HasilTes: CheckCache('NILAI'),
  JawabanTes: CheckCache('JAWABAN') ? CheckCache('JAWABAN') : [],
  JobList: CheckCache('JABATAN'),
  LevelList: [
    {
      id: 1,
      name: 'Staff',
    },
    {
      id: 2,
      name: 'Supervisor',
    },
    {
      id: 3,
      name: 'Head/Manager',
    },
  ],
  dataLoading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NILAI:
      return {
        ...state,
        HasilTes: action.data,
      };

    case IS_LOADING:
      return {
        ...state,
        dataLoading: action.data,
      };

    default:
      return state;
  }
};
