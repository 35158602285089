import './App.css';
// import WebCam from './WebCam';

import { BrowserRouter as Router } from 'react-router-dom';
import Content from './content';

import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';

import AuthReducer from './Store/reducer/AuthReducer';
import DataReducer from './Store/reducer/DataReducer';

const rootReducer = combineReducers({
  auth: AuthReducer,
  data: DataReducer,
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

// login pilihan shift dan pulang/masuk

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Content />
      </Router>
      
    </Provider>
  );
}

export default App;
