import React, { lazy } from 'react';
// import loadable from '@loadable/component';
// const GuestList = lazy(() => import('./views/Mine/Guest/GuestList'));
const Home = lazy(() => import('./Page/Home'));
const Error = lazy(() => import('./Page/Error/error'));

const Login = lazy(() => import('./Page/Login/Login'));
const Logout = lazy(() => import('./Page/Login/Logout'));
const Forgot = lazy(() => import('./Page/Login/Forgot'));
const Signup = lazy(() => import('./Page/Login/Signup'));
const Profile = lazy(() => import('./Page/Profile/Profile'));
const QuizAll = lazy(() => import('./Page/Profile/QuizAll'));

export default [
  {
    path: '/',
    component: Home,
    name: 'home',
    exact: true,
    header: true,
    footer: true,
    active: true,
    private: false,
    reprivate: false,
  },

  {
    path: '/login',
    component: Login,
    name: 'login',
    exact: true,
    header: true,
    footer: true,
    active: true,
    private: false,
    reprivate: true,
  },
  {
    path: '/logout',
    component: Logout,
    name: 'logout',
    exact: true,
    header: true,
    footer: true,
    active: true,
    private: true,
    reprivate: false,
  },
  {
    path: '/forgot',
    component: Forgot,
    name: 'forgot',
    exact: true,
    header: true,
    footer: true,
    active: true,
    private: false,
    reprivate: true,
  },
  {
    path: '/signup',
    component: Signup,
    name: 'signup',
    exact: true,
    header: true,
    footer: true,
    active: true,
    private: false,
    reprivate: true,
  },
  {
    path: '/profile',
    component: Profile,
    name: 'profile',
    exact: true,
    header: true,
    footer: true,
    active: true,
    private: true,
    reprivate: true,
  },
  {
    path: '/quisioner',
    component: QuizAll,
    name: 'quisioner',
    exact: true,
    header: true,
    footer: true,
    active: true,
    private: true,
    reprivate: true,
  },
  {
    path: '*',
    component: Error,
    name: 'Error',
    exact: true,
    header: true,
    footer: true,
    active: true,
    private: false,
    reprivate: false,
  },
];
