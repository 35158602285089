import ApiGate from '../ApiGate';

export async function LoginApi(email, password) {
  let functionName = 'Login';
  let url = `/user/login`;
  let params = { email, password };
  return await ApiGate(url, 'POST', functionName, false, params);
}

export async function SignupApi(params) {
  let functionName = 'Signup';
  let url = `/user/register`;
  // let params = { username, password };
  return await ApiGate(url, 'POST', functionName, false, params);
}

export async function ForgotPasswordApi(params) {
  let functionName = 'ForgotPassword';
  let url = `/user/auth`;
  // let params = { username, password };
  return await ApiGate(url, 'PUT', functionName, true, params);
}

export function CheckToken(token = true) {
  let uidToken = localStorage.getItem('UID');

  if (!uidToken) {
    return false;
  } else {
    uidToken = JSON.parse(uidToken);
  }
  if (!uidToken.token) return false;

  const now = new Date();
  let kurang = new Date(uidToken.expiryDate).getTime() - now.getTime();

  if (kurang < 0) {
    try {
      localStorage.removeItem('UID');
      return false;
    } catch (exception) {
      return false;
    }
  }
  if (token) {
    return uidToken.token;
  } else {
    return uidToken.userId;
  }
}
