export class AuthModel {
  constructor(
    userId,
    name,
    email,
    phone,
    alamat,
    tgl_lahir,
    status,
    token,
    level,
    jabatan
  ) {
    this.userId = userId;
    this.name = name;
    this.email = email;
    this.phone = phone;
    this.alamat = alamat;
    this.tgl_lahir = tgl_lahir;
    this.status = status;
    this.token = token;
    this.level = level;
    this.jabatan = jabatan;
  }
}
