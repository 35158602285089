import {
  cekNilaiApi,
  SubmitAnswerApi,
  deleteHasilApi,
} from '../Context/DataContent';
import { saveDataToStorage } from '../ApiGate';
import { SoalModel } from '../Model/DataModel';

export const GET_HISTORY = 'GET_HISTORY';
export const GET_NILAI = 'GET_NILAI';
export const IS_LOADING = 'IS_LOADING';

const Failed = (params, msg = null) => {
  return async (dispatch) => {
    //console.log('Failed', params, msg);
    if (msg) {
      if (msg.message) {
        alert(msg.message[0]);
      } else {
        alert(msg.errors);
      }
    }
    dispatch({
      type: params,
      data: { failed: true, msg: msg ? msg.message[0] : '' },
    });
    dispatch({ type: IS_LOADING, data: false });

    return false;
  };
};

export const DeleteHasil = () => {
  return async (dispatch) => {
    dispatch({ type: IS_LOADING, data: true });

    let res = await deleteHasilApi();

    if (res.success) {
      if (res.data.length === 0) return Failed(GET_NILAI);

      // saveDataToStorage({ data }, new Date(), 'JABATAN');
      // dispatch({ type: GET_NILAI, data });
      dispatch({ type: IS_LOADING, data: false });
      return true;
    } else {
      dispatch(Failed(GET_NILAI, res));
      return { success: false, error: res.errors };
    }
  };
};

export const CekNilai = () => {
  return async (dispatch) => {
    dispatch({ type: IS_LOADING, data: true });

    //console.log('cekNilaiApi1');
    let res = await cekNilaiApi();
    // console.log('cekNilaiApi2', res);

    if (res.success) {
      if (res.data.length === 0) return Failed(GET_NILAI);
      // if (res.data.soal) {
      //belum tes
      let newData;
      let newCFIT = [];
      if (!res.data.result) {
        let data = res.data
          .map((tipeSoal, i) => {
            let timeleft = tipeSoal.detail.pengaturan.time * 1000 * 60;
            let soal = tipeSoal.detail.soal;

            if (tipeSoal.type === 'C') {
              // let filterSoal_C = res.data.filter((soalC) => soalC.type === 'C');
              newCFIT = soal.map((soalCFIT) => {
                let pengaturan = {
                  soal: 'CFIT Part ' + soalCFIT.id,
                  example: soalCFIT.examples_image,
                  time: soalCFIT.time,
                  num_answer: soalCFIT.num_answer,
                  part: soalCFIT.part,
                };
                return new SoalModel(
                  'CC',
                  'CFIT Part ' + soalCFIT.id,
                  soalCFIT.cfit_questions.sort((a, b) => {
                    let textA = a.no;
                    let textB = b.no;
                    return textA < textB ? -1 : textA < textB ? 1 : 0;
                  }),
                  pengaturan,
                  soalCFIT.time * 1000 * 60
                );
              });
            } else {
              return new SoalModel(
                tipeSoal.type,
                tipeSoal.soal,
                soal,
                tipeSoal.detail.pengaturan,
                timeleft
              );
            }
          })
          .filter((value) => value !== undefined);

        //console.log('data ceknilai map 1 ', data);
        //console.log('newCFIT ', newCFIT);

        data = [...data, ...newCFIT].sort((a, b) => {
          let textA = a.id;
          let textB = b.id;
          return textA < textB ? -1 : textA < textB ? 1 : 0;
        });
        //console.log('data ceknilai map 2 ', data);

        newData = {
          soal: data,
          answered: false,
          start: false,
        };
      } else {
        newData = {
          ...res.data,
          answered: true,
        };
      }
      dispatch({ type: GET_NILAI, data: newData });
      // saveDataToStorage({ data: newData }, new Date(), 'NILAI');
      dispatch({ type: IS_LOADING, data: false });
      return newData;
    } else {
      dispatch(Failed(GET_NILAI, res));
      return { success: false, error: res.errors };
    }
  };
};

export const ChooseAnswer = (pass, choose) => {
  return async (dispatch, getState) => {
    let HasilTes = getState().data.HasilTes;

    dispatch({ type: IS_LOADING, data: true });

    let data = pass.map((soal) => {
      return new SoalModel(
        soal.id,
        soal.questionTitle,
        soal.questionText,
        soal.answerOptions,
        soal.gambar,
        choose,
        soal.isRequired
      );
    });
    let newData = {
      soal: data,
      keterangan: HasilTes.keterangan,
    };

    dispatch({ type: GET_NILAI, data: newData });
    saveDataToStorage({ data: newData }, new Date(), 'NILAI');
    dispatch({ type: IS_LOADING, data: false });
    return newData;
  };
};

export const UpdateTest = (type = 'time', update) => {
  return async (dispatch, getState) => {
    let HasilTes = getState().data.HasilTes;

    //console.log('update', update);

    let choose;
    if (type === 'time') {
      choose = HasilTes.soal.map((soal) => {
        // if (update.timeleft <= 1000) await dispatch(UpdateTest('stop', 0));

        if (soal.typeSoal === update.typeSoal) {
          return update;
        }
        return soal;
      });
    }
    // console.log('choose', choose);

    if (type === 'answer') {
      choose = HasilTes.soal.map((soal) => {
        if (soal.id === update.questions.id) {
          if (update.questions.id === 'CC') {
            if (soal.typeSoal === update.questions.typeSoal) {
              return {
                ...soal,
                listJawaban: update.jawaban,
                answered: true,
              };
            } else {
              return soal;
            }
          } else {
            return {
              ...soal,
              listJawaban: update.jawaban,
              answered: true,
            };
          }
        }
        return soal;
      });
    }

    let newData =
      type === 'time'
        ? {
            ...HasilTes,
            keterangan: { ...HasilTes.keterangan, timeleft: update },
          }
        : type === 'answer'
        ? {
            ...HasilTes,
            soal: choose,
            startTime: new Date(),
          }
        : type === 'start'
        ? {
            ...HasilTes,
            start: true,
            startTime: new Date(),
          }
        : {
            // stop / finish
            ...HasilTes,
            start: false,
            answered: true,
          };

    await dispatch({ type: GET_NILAI, data: newData });
    if (type === 'time') {
      if (update > 0)
        await saveDataToStorage({ data: newData }, new Date(), 'NILAI');
      return update;
    } else {
      await saveDataToStorage({ data: newData }, new Date(), 'NILAI');
    }
    return newData;
  };
};

export const SubmitTest = () => {
  return async (dispatch, getState) => {
    let HasilTes = getState().data.HasilTes;

    let mapping_answer;
    let CFIT = [];
    HasilTes.soal.map((soal) => {
      if (soal.id === 'CC') {
        CFIT.push(soal.listJawaban);
      }
    });
    //console.log('CFIT', CFIT);
    // console.log('HasilTes.soal', HasilTes.soal);
    mapping_answer = HasilTes.soal
      .map((soal) => {
        if (soal.id === 'B')
          return {
            type: soal.id,
            soal: soal.typeSoal,
            jwb: soal.listJawaban.map((jawaban) => {
              return {
                id: jawaban.id,
                jwb_1: jawaban.jwb
                  ? jawaban.jwb[0]
                    ? jawaban.jwb[0]
                    : ''
                  : '',
                jwb_2: jawaban.jwb
                  ? jawaban.jwb[1]
                    ? jawaban.jwb[1]
                    : ''
                  : '',
              };
            }),
          };

        if (soal.id === 'CC') {
          return null;
        }
        if (soal.id === 'D')
          return {
            type: soal.id,
            soal: soal.typeSoal,
            jwb: soal.listJawaban.map((jawab) => ({
              no: jawab.id,
              jwb: jawab.jwb,
            })),
          };

        return {
          type: soal.id,
          soal: soal.typeSoal,
          jwb: soal.listJawaban,
        };
      })
      .filter((soal) => soal !== null);

    if (CFIT.length > 0) {
      let new_answer = [];
      CFIT.map((part) => {
        // console.log('part', part);
        part.map((part_answer) => {
          // console.log('part_answer', part_answer);
          new_answer.push(part_answer);
        });
      });
      let append_cfit = {
        type: 'C',
        soal: 'cfit',
        jwb: new_answer,
      };
      //console.log('append_cfit', append_cfit);
      //console.log('');
      mapping_answer.push(append_cfit);
    }

    //console.log('mapping_answer 2', mapping_answer);
    mapping_answer = {
      jwb: JSON.stringify(
        mapping_answer.sort((a, b) => {
          let textA = a.type;
          let textB = b.type;
          return textA < textB ? -1 : textA < textB ? 1 : 0;
        })
      ),
    };
    // return;
    let res = await SubmitAnswerApi(mapping_answer);
    //console.log('res', res);
    let newData;
    if (res.success) {
      newData = {
        // stop / finish
        ...HasilTes,
        result: res.data.result,
        start: false,
        answered: true,
      };
    } else {
      newData = {
        // stop / error jawaban
        ...HasilTes,
        start: false,
        answered: true,
        error:
          'Koneksi ke server Bermasalah. Silahkan hubungi admin untuk info lebih lanjut.',
      };
    }
    await saveDataToStorage({ data: mapping_answer }, new Date(), 'JAWABAN');

    await dispatch({ type: GET_NILAI, data: newData });
    await saveDataToStorage({ data: newData }, new Date(), 'NILAI');

    return res;
  };
};

//
